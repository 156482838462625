import { useCallback } from 'preact/hooks';
import * as drawIoPluginMessageApi from '../util/drawio-plugin-message-api';

function useDrawIoPluginsApi(drawIoRef) {
  const renderLinkIcons = useCallback(
    (links) =>
      drawIoPluginMessageApi.sendRenderLinkIconsMessage(
        drawIoRef.current,
        links,
      ),
    [drawIoRef],
  );

  const removeLinkIcons = useCallback(
    (objectIds) =>
      drawIoPluginMessageApi.sendRemoveLinkIconsMessage(
        drawIoRef.current,
        objectIds,
      ),
    [drawIoRef],
  );

  const activateLinkIcons = useCallback(
    (objectIds) =>
      drawIoPluginMessageApi.sendActivateLinkIconsMessage(
        drawIoRef.current,
        objectIds,
      ),
    [drawIoRef],
  );

  const deactivateLinkIcons = useCallback(
    (objectIds) =>
      drawIoPluginMessageApi.sendDeactivateLinkIconsMessage(
        drawIoRef.current,
        objectIds,
      ),
    [drawIoRef],
  );

  const highlight = useCallback(
    (opacity, objectIds) =>
      drawIoPluginMessageApi.sendHighlightMessage(
        drawIoRef.current,
        opacity,
        objectIds,
      ),
    [drawIoRef],
  );

  const setSavingStateSuccess = useCallback(
    () =>
      drawIoPluginMessageApi.sendStateSavingSuccessMessage(drawIoRef.current),
    [drawIoRef],
  );

  const setSavingStateFailed = useCallback(
    () =>
      drawIoPluginMessageApi.sendStateSavingFailedMessage(drawIoRef.current),
    [drawIoRef],
  );

  const configureDeleteConfirmation = useCallback(
    (objectIds, message) =>
      drawIoPluginMessageApi.sendConfigureDeleteConfirmationMessage(
        drawIoRef.current,
        objectIds,
        message,
      ),
    [drawIoRef],
  );

  const initMenus = useCallback(
    (configuration) =>
      drawIoPluginMessageApi.sendInitMenusMessage(
        drawIoRef.current,
        configuration,
      ),
    [drawIoRef],
  );

  const exportWithObjectData = useCallback(
    (originalExportMessage) =>
      drawIoPluginMessageApi.sendExportWithObjectDataMessage(
        drawIoRef.current,
        originalExportMessage,
      ),
    [drawIoRef],
  );

  const informDrawIoIsLoaded = useCallback(
    () =>
      drawIoPluginMessageApi.sendDrawIoLoadedMessage(
        drawIoRef.current,
      ),
    [drawIoRef],
  );

  return {
    renderLinkIcons,
    removeLinkIcons,
    activateLinkIcons,
    deactivateLinkIcons,
    highlight,
    setSavingStateFailed,
    setSavingStateSuccess,
    configureDeleteConfirmation,
    initMenus,
    exportWithObjectData,
    informDrawIoIsLoaded
  };
}

export default useDrawIoPluginsApi;
